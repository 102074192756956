.newsletter__wrapper {
  padding-right: calc(4rem / var(--font-body-scale));
  padding-left: calc(4rem / var(--font-body-scale));
}

@media screen and (min-width: 750px) {
  .newsletter__wrapper {
    padding-right: 9rem;
    padding-left: 9rem;
  }
}

.newsletter__wrapper > * {
  margin-top: 0;
  margin-bottom: 0;
}

.newsletter__wrapper > * + * {
  margin-top: 2rem;
}

.newsletter__wrapper > * + .newsletter-form {
  margin-top: 3rem;
}

.newsletter__subheading {
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

.newsletter__wrapper .newsletter-form__field-wrapper {
  max-width: 36rem;
}

.newsletter-form__field-wrapper .newsletter-form__message {
  margin-top: 1.5rem;
}

.newsletter__button {
  margin-top: 3rem;
  width: fit-content;
}

@media screen and (min-width: 750px) {
  .newsletter__button {
    flex-shrink: 0;
    margin: 0 0 0 1rem;
  }
}
